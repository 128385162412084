import React from 'react'
import { Link } from 'gatsby'

import '@debijenkorf/navheader/build/style.css'

const NavHeader = ({ items }) => {
  return (
    <ul className="dbk-dropdown-nav--tier-1">
      {items.map(({ columns, name, url }, index) => (
        <li
          key={name.concat(index.toString())}
          className="dbk-dropdown-nav--item dbk-dropdown-nav--item_tier-1 mb-0"
        >
          <span className="dbk-dropdown-nav--link dbk-dropdown-nav--link_tier-1">
            <Link to={url} activeClassName="dbk-active">
              {name}
            </Link>
          </span>
        </li>
      ))}
    </ul>
  )
}

NavHeader.propTypes = {}

function mapProps ({ items, locale }) {
  return items.map(item => {
    let { slug, title } = item
    title = slug === 'home' ? 'Home' : title
    slug = slug === 'home' ? `/${ locale }` : `/${ locale }/${ slug }`
    return {
      name: title,
      url: slug
    }
  })
}

export default props => (
  <nav className="dbk-dropdown-nav">
    <NavHeader items={mapProps(props)} />
  </nav>
)

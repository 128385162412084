import React from 'react'

const Footer = ({ children }) => (
  <footer className="dbk-footer py-4">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">{children}</div>
      </div>
    </div>
  </footer>
)

export default Footer

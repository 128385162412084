import React from 'react'
import { PropTypes } from 'prop-types'
import { INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const GenericInlineAnchor = (href, children) => <a href={href}>{children}</a>

const Body = ({ data, locale }) => {
  const result = documentToReactComponents(data, {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        let {
          data: {
            target: {
              fields: { file }
            }
          }
        } = node
        return GenericInlineAnchor(file['en'].url, children)
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        let {
          data: {
            target: {
              fields: { slug }
            }
          }
        } = node

        return GenericInlineAnchor(`/${ locale }/${ slug[locale] }`, children)
      }
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegement, index) => {
        return [...children, index > 0 && <br key={index} />, textSegement]
      }, [])
    }
  })

  return <div>{result}</div>
}

Body.propTypes = {
  locale: PropTypes.string.isRequired,
  data: PropTypes.object
}

export default Body

import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'

const LanguageSwitch = ({ currentLang }) => (
  <StaticQuery
    query={graphql`
      query LanguageSwitchQuery {
        site {
          siteMetadata {
            languages {
              langs
            }
          }
        }
      }
    `}
    render={data => {
      // Deconstruct langs array from data
      let {
        siteMetadata: {
          languages: { langs }
        }
      } = data.site
      // filter out languages that don't match
      // the current language
      let otherLangs = langs.filter(lang => {
        return lang !== currentLang
      })
      return (
        <ul
          style={{
            listStyle: 'none',
            margin: 0,
            display: 'flex'
          }}
        >
          {otherLangs.map(lang => {
            return (
              <li
                style={{
                  margin: 0,
                  padding: '0.5em'
                }}
                key={lang}
              >
                <Link to={`/${ lang }`}>{lang}</Link>
              </li>
            )
          })}
        </ul>
      )
    }}
  />
)

export default LanguageSwitch

LanguageSwitch.propTypes = {
  currentLang: PropTypes.string.isRequired
}

import React from 'react'
import * as PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import PrimaryNavigation from '../components/Navigation/Primary'
import SecondaryNavigation from '../components/Navigation/Secondary'
import LanguageSwitch from '../components/LanguageSwitch'
import Body from '../components/Body'

import { Subnav, SubnavItem } from '../components/Subnav/lib'

const PageTemplate = props => {
  let {
    title,
    node_locale: locale,
    body: { json },
    subpages,
    page: parentPage
  } = props.data.contentfulPage

  subpages = parentPage ? parentPage[0].subpages : subpages

  const {
    node: { items: primaryNavItems }
  } = props.data.allContentfulNavigation.edges.filter(edge => {
    return edge.node.title === 'Primary'
  })[0]

  const {
    node: { items: secondaryNavItems }
  } = props.data.allContentfulNavigation.edges.filter(edge => {
    return edge.node.title === 'Secondary'
  })[0]

  return (
    <Layout
      renderPrimaryNav={
        <PrimaryNavigation items={primaryNavItems} locale={locale} />
      }
      renderSecondaryNav={
        <SecondaryNavigation items={secondaryNavItems} locale={locale} />
      }
      renderLanguageSwitch={<LanguageSwitch currentLang={locale} />}
      renderLogo={<Link to={`/${ locale }`} className="dbk-header--logo" />}
    >
      <SEO title={title} lang={locale} />

      {subpages && (
        <div className="col-12 col-lg-3">
          <aside>
            <Subnav className="my-4">
              {subpages.map(({ path, label, id }) => (
                <SubnavItem key={id}>
                  <Link activeClassName="active" to={`/${ locale }/${ path }`}>
                    {label}
                  </Link>
                </SubnavItem>
              ))}
            </Subnav>
          </aside>
        </div>
      )}

      <div className="col-12 col-lg-9 pb-4">
        <h1 className="my-4">{title}</h1>
        <Body data={json} locale={locale} />
      </div>
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($slug: String!, $node_locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $node_locale }) {
      title
      node_locale
      body {
        json
      }
      # query sub pages (when parents)
      subpages {
        id
        label: title
        path: slug
      }
      # query sub pages (from parent when sub page)
      page {
        subpages {
          id
          label: title
          path: slug
        }
      }
    }
    #  query navigation (all)
    allContentfulNavigation(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          title
          node_locale
          items {
            id
            title
            slug
          }
        }
      }
    }
  }
`

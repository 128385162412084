const BASE = 'subnav';
const ELEMENT_DELIMITER = '__';

export const COMPONENT_CLASSNAMES = {
  block: BASE,
  link: `${BASE}${ELEMENT_DELIMITER}link`,
  item: `${BASE}${ELEMENT_DELIMITER}item`,
  list: `${BASE}${ELEMENT_DELIMITER}list`,
  isActive: 'active',
};

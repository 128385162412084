import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const SecondaryNav = ({ items, locale }) => {
  return items.map(item => {
    let { title, slug, id } = item
    slug = `/${ locale }/${ slug }`
    return (
      <Link key={id} to={slug}>
        {title}
      </Link>
    )
  })
}

SecondaryNav.propTypes = {
  items: PropTypes.array,
  locale: PropTypes.string.isRequired
}

export default SecondaryNav

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { COMPONENT_CLASSNAMES } from './constants';

const cx = classNames.bind(COMPONENT_CLASSNAMES);

const SubnavItem = ({ onClick = () => {}, children = null }) => {
  if (children !== null) {
    const { className: childClassName = '' } = children.props;
    return (
      <li className={ cx('item') }>
        {React.cloneElement(children, { className: cx('link', childClassName), onClick })}
      </li>
    );
  }
  return null;
};

SubnavItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export { SubnavItem };

/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Footer from '../Footer'

const Layout = ({
  renderPrimaryNav,
  renderSecondaryNav,
  renderLanguageSwitch,
  renderLogo,
  children
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
            version
          }
        }
      }
    `}
    render={data => (
      <div className="dbk-wrapper">
        <header
          className="dbk-header"
          style={{
            position: 'relative',
            padding: '1em 0 0'
          }}
        >
          <div className="dbk-header--supplementary">
            <div className="container">
              {renderLogo}
              <div className="dbk-header--flyouts">{renderLanguageSwitch}</div>
            </div>
          </div>
        </header>
        {renderPrimaryNav}
        <div className="dbk-body-wrapper">
          <div className="container">
            <div className="row">{children}</div>
          </div>
        </div>
        <Footer>
          {renderSecondaryNav}
          <div className="d-flex justify-content-center">
            <div className="t-light-grey">
              v{data.site.siteMetadata.version}
            </div>
          </div>
        </Footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { COMPONENT_CLASSNAMES } from './constants';
import { SubnavItem } from './';

const cx = classNames.bind(COMPONENT_CLASSNAMES);

const Subnav = ({ className = '', onClick = () => {}, items = [], children = null }) => {
  const renderItems = () =>
    items.map((item) => {
      const onClickFn = () => onClick(item);
      const { label, isActive } = item;
      const className = cx({ active: isActive }, 'btn', 'btn--naked', 'px-3');
      return (
        <SubnavItem key={ label }>
          <button className={ className } onClick={ onClickFn }>
            {label}
          </button>
        </SubnavItem>
      );
    });

  return (
    <nav className={ cx('block', className) }>
      <ul className={ cx('list') }>{children === null ? renderItems() : children}</ul>
    </nav>
  );
};

Subnav.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      slug: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export { Subnav };
